import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Checkbox, Input, RadioButton } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
// libs
import moment from "moment";
// logo
import FilterIcon from "../../assets/images/Icon/FilterIcon.svg";
import SearchIcon from "../../assets/images/Icon/SearchIcon.svg";
import CrossIcon from "../../assets/images/Icon/Cross_black.svg";
// component
import { Table } from "../../components/table/table";
import { Pagination } from "../../components/pagination/pagination";
// store
import { fetchALLGroup, resetGroupList } from "../../store/groupList";
//routes
import { CCTV_CREATE_ROUTE, CCTV_DETAIL_ROUTE } from "../../routes";
// css
import "./cctv.css";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";
import config from "../../config/config";
import { CustomDatePicker } from "../../components/datePicker/datePicker";
import { fetchAllCCTV, resetCCTVLIST } from "../../store/cctvList";
import { DropDownList } from "@progress/kendo-react-all";

const header = [
  {
    name: "Camera Name",
  },
  {
    name: "Package",
    width: "250px",
  },
  {
    name: "Description",
    width: "150px",
  },
  {
    name: "Camera Position (Lat/ Long)",
    width: "150px",
  },
  {
    name: "Camera Location Description",
    width: "150px",
  },
  {
    name: "Camera Map ID",
    width: "150px",
  },
  {
    name: "Source Available",
    width: "150px",
  },
  {
    name: "Last Modified Date Time",
    width: "150px",
  },
];

export const CCTVManagment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { general_config_data } = useSelector((state) => state.generalConfig);
  const all_packages_dict = {};
  ["All", ...general_config_data.general_config_data.package].forEach(
    (element) => {
      all_packages_dict[element] = false;
    }
  );
  const [filterData, setFilterData] = useState({
    filterPkg: [],
    source_available: "All",
  });
  const { GET_CCTV } = config.api_endpoint;
  const [newPage, setNewPage] = useState(false);
  const [cctvData, setCCTVData] = useState([]);
  const [activePages, setActivePages] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [filterSJD, setFilterSJD] = useState();
  const [filterEJD, setFilterEJD] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filtered, setFiltered] = useState(false);
  const { cctvList, loading } = useSelector((state) => state.cctvList);

  const handleTableClick = (id) => {
    navigate(`${CCTV_DETAIL_ROUTE}?id=${id}`);
  };

  useEffect(() => {
    dispatch(
      fetchAllCCTV({
        page: 1,
        page_size: 20,
      })
    );
    return () => {
      dispatch(resetCCTVLIST());
    };
  }, []);

  useEffect(() => {
    const _cctv_data = [];
    if (!loading && cctvList.cctvList && cctvList.cctvList.items.length > 0) {
      for (let i = 0; i < cctvList.cctvList.items.length; i++) {
        _cctv_data.push({
          id: cctvList.cctvList.items[i].camera_no,
          item1: cctvList.cctvList.items[i].camera_no,
          item2: cctvList.cctvList.items[i].package,
          item3: cctvList.cctvList.items[i].description,
          item4: `${cctvList.cctvList.items[i].lat}, ${cctvList.cctvList.items[i].long}`,
          item5: cctvList.cctvList.items[i].location
            ? cctvList.cctvList.items[i].location
            : "-",
          item6: cctvList.cctvList.items[i].map_id
            ? cctvList.cctvList.items[i].map_id
            : "-",
          item7: cctvList.cctvList.items[i].is_available ? "Yes" : "No",
          item8: cctvList.cctvList.items[i].modified_at
            ? moment(cctvList.cctvList.items[i].modified_at).format(
                "DD MMM YYYY, hh:mm:ss"
              )
            : "-",
        });
      }
      setNewPage(cctvList.cctvList.newPage);
    }
    setCCTVData(_cctv_data);
  }, [cctvList]);

  const handlePageChange = async (page) => {
    console.log("this one doing");
    setActivePages(page + 1);
    console.log(page);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    let APIParams = {};
    if (filtered) {
      APIParams = {
        data_type: "CCTV",
        statistics: {
          page: page + 1,
          pageSize: 20,
          filter_packages: filterData.filterPkg.join(","),
          filter_source_available: filterData.source_available ? 1 : 0,
        },
      };
    } else {
      APIParams = {
        data_type: "CCTV",
        statistics: {
          page: page + 1,
          pageSize: 20,
        },
      };
    }
    const results = await api.post(GET_CCTV, JSON.stringify(APIParams), token);
    const _cctv_data = [];
    for (let i = 0; i < results.data.items.length; i++) {
      _cctv_data.push({
        id: results.data.items[i].camera_no,
        item1: results.data.items[i].camera_no,
        item2: results.data.items[i].package,
        item3: results.data.items[i].description,
        item4: `${results.data.items[i].lat}, ${results.data.items[i].long}`,
        item5: results.data.items[i].location
          ? results.data.items[i].location
          : "-",
        item6: results.data.items[i].map_id
          ? results.data.items[i].map_id
          : "-",
        item7: results.data.items[i].is_available ? "Yes" : "No",
        item8: results.data.items[i].modified_at
          ? moment(results.data.items[i].modified_at).format(
              "DD MMM YYYY, hh:mm:ss"
            )
          : "-",
      });
    }
    setNewPage(results.data.newPage);
    setCCTVData([...cctvData, ..._cctv_data]);
  };

  const handlePkg = (pkg) => {
    console.log(pkg);
    if (filterData.filterPkg.find((i) => i === pkg)) {
      const filtered = filterData.filterPkg.filter((j) => j !== pkg);
      setFilterData({
        ...filterData,
        filterPkg: filtered,
      });
    } else {
      filterData.filterPkg.push(pkg);
      setFilterData({
        ...filterData,
      });
    }
  };

  const FilterApply = async () => {
    setFilterLoading(true);
    setFiltered(true);
    setShowFilter(false);
    setActivePages(1);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const results = await api.post(
      GET_CCTV,
      JSON.stringify({
        data_type: "CCTV",
        statistics: {
          page: 1,
          pageSize: 20,
          filter_packages: filterData.filterPkg.join(","),
          filter_source_available:
            filterData.source_available === "All"
              ? ""
              : filterData.source_available === "Yes"
              ? 1
              : 0,
        },
      }),
      token
    );
    console.log(results);
    const _cctv_data = [];
    for (let i = 0; i < results.data.items.length; i++) {
      _cctv_data.push({
        id: results.data.items[i].camera_no,
        item1: results.data.items[i].camera_no,
        item2: results.data.items[i].package,
        item3: results.data.items[i].description,
        item4: `${results.data.items[i].lat}, ${results.data.items[i].long}`,
        item5: results.data.items[i].location
          ? results.data.items[i].location
          : "-",
        item6: results.data.items[i].map_id
          ? results.data.items[i].map_id
          : "-",
        item7: results.data.items[i].is_available ? "Yes" : "No",
        item8: results.data.items[i].modified_at
          ? moment(results.data.items[i].modified_at).format(
              "DD MMM YYYY, hh:mm:ss"
            )
          : "-",
      });
      setNewPage(results.data.newPage);
    }
    setCCTVData(_cctv_data);
    setFilterLoading(false);
  };

  const FilterReset = () => {
    setActivePages(1);
    setShowFilter(false);
    setFiltered(false);
    setFilterData({
      ...filterData,
      filterPkg: [],
      source_available: "All",
    });
    dispatch(
      fetchAllCCTV({
        page: 1,
        page_size: 20,
      })
    );
  };

  console.log(filterData);

  return (
    <div>
      {showFilter && (
        <Dialog>
          <div className="CCTV-filter-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={() => setShowFilter(false)}
            >
              <img src={CrossIcon} />
            </div>
            <div className="CCTV-filter-title">Filter</div>
            <div>
              <div className="CCTV-filter-sub-title">Package</div>
              <div className="CCTV-filter-box">
                {Object.entries(all_packages_dict).map(([key, value]) => (
                  <div
                    className="CCTV-filter-item"
                    key={1 + Math.random() * (100 - 1)}
                  >
                    <Checkbox
                      // defaultChecked={value}
                      checked={
                        filterData.filterPkg.find((i) => i === key)
                          ? true
                          : false
                      }
                      label={key}
                      onClick={() => handlePkg(key)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="CCTV-filter-sub-title">Source availability</div>
              <div style={{ display: "flex", gap: "20px", margin: "20px" }}>
                {/* <div style={{ flex: 1 }}>
                  <RadioButton
                    className="details-radio"
                    label={"Yes"}
                    name="source_available"
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        source_available: true,
                      })
                    }
                    checked={filterData.source_available && true}
                  />
                </div> */}
                <div style={{ flex: 1 }}>
                  {/* <RadioButton
                    className="details-radio"
                    label={"No"}
                    name="source_available"
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        source_available: false,
                      })
                    }
                    checked={!filterData.source_available && true}
                  /> */}
                  <DropDownList
                    data={["All", "Yes", "No"]}
                    value={filterData.source_available}
                    style={{
                      background: "white",
                      height: "48px",
                    }}
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        source_available: e.target.value,
                      })
                    }
                    className="CCTV_form_input_editable"
                  />
                </div>
              </div>
            </div>
            <div className="CCTV_Filter_button_main_box">
              <div className="CCTV_Filter_clear_button" onClick={FilterReset}>
                Clear
              </div>
              <div className="CCTV_Filter_apply_button" onClick={FilterApply}>
                Apply
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {loading || filterLoading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div className="CCTV">
          <div className="Group_toolbar">
            <div className="Group_toolbar_title">CCTV Management</div>
            <div className="Group_toolbar_child_box">
              <div
                className="Group_toolbar_add_button"
                onClick={() => navigate(CCTV_CREATE_ROUTE)}
              >
                <div>+</div>
                <div>Add</div>
              </div>
              <div
                className="Group_toolbar_filter_button"
                onClick={() => setShowFilter(true)}
              >
                <img src={FilterIcon} />
              </div>
              {/* <div
                className="Group_toolbar_filter_button"
                // onClick={handleSearchIconClicked}
              >
                <img src={SearchIcon} />
              </div> */}
            </div>
          </div>
          {/* {showSearch && (
          <div className="Approval_selection_bar">
            <Input
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              placeholder="Search..."
              onKeyDown={(e) => e.key === "Enter" && applyFilters()}
            />
            <div
              className="Approval_toolbar_add_button"
              onClick={() => {
                applyFilters();
              }}
            >
              <div>Search</div>
            </div>
          </div>
        )} */}
          {cctvData.length > 0 ? (
            <Table
              header={header}
              data={cctvData}
              handleTableClick={handleTableClick}
              handlePageChange={handlePageChange}
              activePages={activePages}
              showSearch={showSearch}
              hasMore={newPage}
              height={"calc(-223px + 100vh)"}
            />
          ) : (
            <p style={{ textAlign: "center" }}>No records found.</p>
          )}
          {/* <Pagination
            pageCount={groupList.groupList && groupList.groupList.total_pages}
          /> */}
        </div>
      )}
    </div>
  );
};
