import React, { useEffect, useState } from "react";
import TableFilter from "../../assets/images/Icon/unsorted.svg";
import "./table.css";
import { Loader } from "@progress/kendo-react-all";
import InfiniteScroll from "react-infinite-scroll-component";

export const Table = (props) => {
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState(null);

  console.log("Table Items:", props.data);
  console.log("props.height:", props.height);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  const handleFilter = (id) => {
    const sorting = data.sort((a, b) =>
      a[`item${id + 1}`] > b[`item${id + 1}`] ? 1 : -1
    );
    setData(() => [...sorting]);
  };
  return (
    <>
      <div style={{ width: "calc(100vw - 260px)" }}>
        <div>
          <div className="Table_header_box Table_header">
            {props.header &&
              props.header.map((data, i) => (
                <div className="Table_flex" key={i}>
                  <div className="Table_text_overflow Table_header_text">
                    {data.name}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div
          style={{
            height: props.showSearch
              ? "calc(100vh - 285px)"
              : props.height
              ? props.height
              : "calc(100vh - 203px)",
            overflowY: "scroll",
          }}
          id="scrollableDiv"
          className="scrolledDIV"
        >
          <InfiniteScroll
            dataLength={data ? data.length : 0}
            scrollableTarget="scrollableDiv"
            loader={
              <Loader
                style={{
                  position: "relative",
                  height: "70px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "white",
                }}
              />
            }
            next={() => props.handlePageChange(props.activePages)}
            hasMore={props.hasMore ? props.hasMore : false}
          >
            {data.length > 0
              ? data.map((data, id) => (
                  <div
                    key={id}
                    className="Table_header_box Table_body"
                    onClick={() =>
                      props.handleTableClick && props.handleTableClick(data.id)
                    }
                  >
                    {props.header &&
                      props.header.map((dat, i) => {
                        if (props.button) {
                          if (props.header.length - 1 > i)
                            return (
                              <div className="Table_flex">
                                {" "}
                                <div
                                  className="Table_text_overflow Table_body_text"
                                  key={i}
                                >
                                  {data[`item${i + 1}`]}
                                </div>
                              </div>
                            );
                        } else {
                          return (
                            <div className="Table_flex">
                              <div
                                key={i}
                                className="Table_text_overflow Table_body_text"
                              >
                                {data[`item${i + 1}`]}
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                ))
              : ""}
          </InfiniteScroll>
        </div>
      </div>
      {/* <div
        id="table"
        // id="scrollableDiv"
        style={{
          maxHeight: props.height
            ? "calc(100vh - " + props.height + ")"
            : "calc(100vh - 163px)",
          overflowY: "auto",
          background: "white",
        }}
      >
        <InfiniteScroll
          dataLength={data ? data.length : 0}
          scrollableTarget="table"
          loader={
            <Loader
              style={{
                position: "relative",
                height: "70px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          }
          next={() => console.log("call")}
          // next={() => {
          //   props.AddData(
          //     props.status,
          //     searchTxtStr,
          //     allFilters.startDate,
          //     allFilters.endDate
          //   );
          // }}
          // hasMore={props.hasMore}
          hasMore={true}
        >
          {props.header && (
            <table>
              <thead
                style={{
                  position: "sticky",
                  top: "0",
                  background: "#EFEFEF",
                }}
              >
                <tr style={{ height: "40px" }}>
                  {props.header &&
                    props.header.map((data, id) => (
                      <th
                        key={id}
                        style={{ cursor: "pointer", width: data.width }}
                        onClick={() => handleFilter(id)}
                      >
                        <div className="Table_header">
                          {data.name}
                          {data.filter !== undefined ? (
                            data.filter === true ? (
                              <img src={TableFilter} />
                            ) : (
                              ""
                            )
                          ) : (
                            <img src={TableFilter} />
                          )}
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {data.length > 0
                  ? data.map((data, id) => (
                      <tr
                        className="TableHandle"
                        key={id}
                        onClick={() =>
                          props.handleTableClick &&
                          props.handleTableClick(data.id)
                        }
                      >
                        {props.header &&
                          props.header.map((dat, i) => {
                            if (props.button) {
                              if (props.header.length - 1 > i)
                                return <td key={i}>{data[`item${i + 1}`]}</td>;
                            } else {
                              return <td key={i}>{data[`item${i + 1}`]}</td>;
                            }
                          })}
                        {props.button && (
                          <td style={{ minWidth: "150px", maxWidth: "155px" }}>
                            <div
                              className="Table_button"
                              onClick={() => {
                                if (props.buttonClick)
                                  props.buttonClick(data.id);
                              }}
                            >
                              {props.button}
                            </div>
                          </td>
                        )}
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          )}
        </InfiniteScroll>
      </div> */}
    </>
  );
};
