import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
// icons
import logout_icon from "../../assets/images/Icon/logout.svg";
import logout_hover_icon from "../../assets/images/Icon/logout_hover_icon.svg";
import Logo from "../../assets/images/Icon/CEreal_Logo_Final.png";
import NoImage from "../../assets/images/Icon/NoImage.png";
import Cross_icon from "../../assets/images/Icon/logout_cross_icon.svg";
// kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
// api
import config from "../../config/config";
// css
import "./navigation.css";
// route
import {
  COMPANY_MANAGEMENT_ROUTE,
  GROUP_MANAGEMENT_ROUTE,
  USER_MANAGEMENT_ROUTE,
  APPROVAL_MANAGEMENT_ROUTE,
  PACKAGE_MANAGMENT,
  CCTV_MANAGMENT,
  OHS_Management,
  OHS_Management_HAZARDS,
  OHS_Management_WORK_ACTIVITY,
  OHS_Management_POTENTIAL,
  OHS_PAGE,
} from "../../routes";

const {
  signout,
  getCurrentLoggedInUserWithPromise,
} = require("../../config/userPool");

const { Get_USER_ENDPOINT } = config.api_endpoint;

const Navigation = ({ children, selected }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const email_user = localStorage.getItem("email");
  const [visible, setVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [picture, setPicture] = useState(null);
  const [ohsFlag, setOhsFlag] = useState(false);
  const [childSelected, setChildSelected] = useState("");

  const items = [
    {
      text: "User Management",
      // icon: "k-i-grid-layout",
      route: USER_MANAGEMENT_ROUTE,
      selected: true,
    },
    {
      text: "Group Management",
      // icon: "k-i-marker-pin-target",
      route: GROUP_MANAGEMENT_ROUTE,
    },
    {
      text: "Company Management",
      // icon: "k-i-group",
      route: COMPANY_MANAGEMENT_ROUTE,
    },
    {
      text: "Approval Management",
      // icon: "k-i-group",
      route: APPROVAL_MANAGEMENT_ROUTE,
    },
    {
      text: "Package Management",
      // icon: "k-i-group",
      route: PACKAGE_MANAGMENT,
    },
    {
      text: "CCTV Management",
      // icon: "k-i-group",
      route: CCTV_MANAGMENT,
    },
    {
      text: "OHS_EXCEL_UPLOAD Management",
      route: OHS_PAGE,
      developer: true,
    },
    {
      text: "OHS Management",
      // icon: "k-i-group",
      route: OHS_Management,
      flag: ohsFlag,
      setFlag: setOhsFlag,
      sub_menu: [
        {
          text: "Hazards",
          route: OHS_Management_HAZARDS,
        },
        {
          text: "Type of Work Activity",
          route: OHS_Management_WORK_ACTIVITY,
        },
        {
          text: "Potential Consequences",
          route: OHS_Management_POTENTIAL,
        },
      ],
    },
  ];

  const CustomItem = (props) => {
    if (props.sub_menu) {
      return (
        <div>
          <DrawerItem {...props} style={{ marginBottom: "0px" }}>
            <div
              className={`navigation_items_box ${
                props.text
                  .replace(/ /g, "")
                  .toLowerCase()
                  .replaceAll("management", "") === selected.toLowerCase() &&
                "selected"
              }`}
              onClick={() =>
                props.flag ? props.setFlag(false) : props.setFlag(true)
              }
            >
              <div className="item-descr-wrap">
                <div>{props.text}</div>
              </div>
              <div>
                {props.flag ? (
                  <span class="k-icon k-font-icon k-i-arrow-chevron-up k-icon-lg"></span>
                ) : (
                  <span class="k-icon k-font-icon k-i-arrow-chevron-down k-icon-lg"></span>
                )}
                {/* <span className={"k-icon " + props.icon} /> */}
                <img />
              </div>
            </div>
          </DrawerItem>
          {props.flag ? (
            <div className={`navigation_sub ${props.flag && "selected"}`}>
              {props.sub_menu.map((child) => (
                <DrawerItem key={child.text}>
                  <div
                    className="navigation_sub_item_box"
                    onClick={() => {
                      setChildSelected(child.text);
                      navigate(child.route);
                    }}
                  >
                    <div
                      className={`${
                        childSelected === child.text && "selected"
                      }`}
                    >
                      {child.text}
                    </div>
                    <div>
                      <span
                        className={`k-icon k-font-icon k-i-arrow-60-right k-icon-lg ${
                          childSelected === child.text && "selected"
                        }`}
                      ></span>
                    </div>
                  </div>
                </DrawerItem>
              ))}
            </div>
          ) : null}
        </div>
      );
    } else {
      if (props.developer === true && email_user.includes("weeswares.com")) {
        return (
          <DrawerItem {...props}>
            <div
              className={`navigation_items_box ${
                props.text
                  .replace(/ /g, "")
                  .toLowerCase()
                  .replaceAll("management", "") === selected.toLowerCase() &&
                "selected"
              }`}
            >
              <div className="item-descr-wrap">
                <div>{props.text}</div>
              </div>
              <div>
                {/* <span className={"k-icon " + props.icon} /> */}
                <img />
              </div>
            </div>
          </DrawerItem>
        );
      } else if (props.developer === undefined) {
        return (
          <DrawerItem {...props}>
            <div
              className={`navigation_items_box ${
                props.text
                  .replace(/ /g, "")
                  .toLowerCase()
                  .replaceAll("management", "") === selected.toLowerCase() &&
                "selected"
              }`}
            >
              <div className="item-descr-wrap">
                <div>{props.text}</div>
              </div>
              <div>
                {/* <span className={"k-icon " + props.icon} /> */}
                <img />
              </div>
            </div>
          </DrawerItem>
        );
      }
    }
  };

  const onSelect = (ev) => {
    setChildSelected("");
    if (isMobile) {
      navigate(ev.itemTarget.route);
    } else {
      navigate(ev.itemTarget.props.route);
    }
  };

  const logout = () => {
    signout();

    localStorage.removeItem("login");
    localStorage.removeItem("username");
    localStorage.removeItem("session_id");
    window.location.href = "/";
    localStorage.setItem("redirect_to", window.location.href);
    setVisible(!visible);
  };

  useEffect(() => {
    async function getUser() {
      let currentLoginUser = await getCurrentLoggedInUserWithPromise();
      console.log("currentLoginUser:", currentLoginUser);
      if (currentLoginUser != null && !picture) {
        if ("picture" in currentLoginUser) {
          setPicture(currentLoginUser.picture);
        }
      }
    }
    getUser();
  }, []);

  return (
    <>
      {visible && (
        <Dialog
          onClose={() => setVisible(!visible)}
          style={{ borderRadius: "8px", zIndex: "100020" }}
        >
          <span
            style={{ float: "right", cursor: "pointer", paddingTop: "15px" }}
          >
            <img src={Cross_icon} onClick={() => setVisible(!visible)} />
          </span>
          <p className="logout_popup_box">Are you sure you want to logout?</p>
          <div className="logout_popup_action_box">
            <button
              className="logout_popup_action_button"
              onClick={() => setVisible(!visible)}
              style={{ color: "#EB7930", background: "#FFFFFF" }}
            >
              No, cancel
            </button>
            <button
              className="logout_popup_action_button"
              onClick={logout}
              style={{ background: "#EB7930", color: "white" }}
            >
              Yes, logout
            </button>
          </div>
        </Dialog>
      )}
      <div style={{ background: "#F5F5F5" }}>
        <div className="custom-toolbar">
          <span className="cereal-title">
            <img src={Logo} style={{ width: "110.77px", height: "40px" }} />
          </span>
          <span
            className="cereal-title custom_toolbar_profile"
            style={{ cursor: "pointer" }}
            //   onClick={() => navigate(USER_DETAIL)}
          >
            <img
              src={picture != null ? picture : NoImage}
              style={{
                width: "45px",
                height: "45px",
                borderRadius: "100%",
              }}
            />
            {/* <img src={DownArrow} className="profile_setting" /> */}
            <span
              className="logout_button"
              onClick={() => setVisible(!visible)}
              onMouseOver={() => setIsHovered(true)}
              onMouseOut={() => setIsHovered(false)}
            >
              <p>{localStorage.getItem("name") || "User"}</p>{" "}
              <img
                src={isHovered ? logout_hover_icon : logout_icon}
                style={{ marginLeft: "10px" }}
              />
            </span>
          </span>
        </div>
        <Drawer
          expanded={true}
          position={"start"}
          mode={"push"}
          items={items.map((item) => ({
            ...item,
            selected:
              item.text.replace(/ /g, "").toLowerCase() ===
              selected.toLowerCase(),
          }))}
          item={CustomItem}
          onSelect={onSelect}
          className="navigation-div"
        >
          <DrawerContent
            style={{ background: "#F5F5F5", height: "calc(100vh - 81px)" }}
          >
            {children}
          </DrawerContent>
        </Drawer>
      </div>
    </>
  );
};

export default Navigation;
