import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import api from "../../config/api";
import config from "../../config/config";
import validator from "validator";
// kendo
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import OtpInput from "react-otp-input";
// icons
import Hide from "../../assets/images/Icon/hide.svg";
import Unhide from "../../assets/images/Icon/unhide.svg";
import Logo from "../../assets/images/Icon/CEreal_Logo_Final.png";
import FooterLogo from "../../assets/images/Icon/CAG-Logo 1@2x.png";
import FullscreenLoader from "../../components/fullScreenLoader";
import "./auth.css";

// cognito
const {
  client_id,
  pool_id,
  cognito_domain,
  cognito_saml_provider,
  cognito_saml_redirect_url,
} = require("../../service");
const AmazonCognitoIdentity = require("amazon-cognito-identity-js");
const AWS = require("aws-sdk");

const poolData = {
  UserPoolId: pool_id, // Your user pool id here
  ClientId: client_id, // Your client id here
};

export const Login = () => {
  const navigate = useNavigate();
  const [loginBtnText, setLoginBtnText] = useState("Login");
  const [showOTP, setShowOTP] = useState(false);
  const [hide, setHide] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideCfmNewPassword, setHideCfmNewPassword] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFirstLogin, setIsFirstlogin] = useState(false);
  const [userAttr, setUserAttr] = useState({});
  const [user, setUser] = useState({});
  let cognitoUser = useRef(null);
  const loginForm = useRef(null);
  const usernameValidationMessage =
    "Your username should contain only letters!";
  const passwordValidationMessage =
    "Please enter password between 6 and 16 characters!";
  const [userName, setUserName] = useState("");
  const [userPassword, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newCfmPassword, setNewCfmPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [showOTPResend, setShowOTPResend] = useState(false);
  const [lastOTPResend, setLastOTPResend] = useState("");

  let { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const [currentURL, setCurrentURL] = useState(pathname);
  const [loginFailCount, setLoginFailCount] = useState(0);
  const [open, setOpen] = useState(false);

  const {
    VERIFY_AUTH_CODE,
    UPDATE_USER_DETAIL,
    CHECK_ADMIN_ACCESS,
    CHECK_PASSWORD,
    GET_USER_ALT_ENDPOINT,
    DISABLE_ACCOUNT_ENDPOINT,
    IS_DISABLED_ACCOUNT_ENDPOINT,
    USER_SESSION_ENDPOINT,
  } = config.api_endpoint;

  useEffect(() => {
    console.log("--- USE EFFECT QUERY ---", pathname);
    setCurrentURL(pathname);
  }, [pathname]);

  useEffect(() => {
    console.log("--- USE EFFECT CURRENT URL ---", currentURL);
    console.log("--- Authorization Code ---", query.get("code"));

    const authorization_code = query.get("code");
    if (authorization_code) {
      setLoading(true);
      api
        .post(
          VERIFY_AUTH_CODE,
          JSON.stringify({ code: authorization_code }),
          null
        )
        .then(async (res) => {
          console.log("verify_auth_code result: ", res);
          setLoading(false);
          console.log("poolData:", poolData);
          let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
          console.log("userPool:", userPool);
          let userData = {
            Username: res.data.user_details.email,
            Pool: userPool,
          };
          console.log("userData:", userData);

          let cognitoSession = new AmazonCognitoIdentity.CognitoUserSession({
            IdToken: new AmazonCognitoIdentity.CognitoIdToken({
              IdToken: res.data.id_token,
            }),
            AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({
              AccessToken: res.data.access_token,
            }),
            RefreshToken: new AmazonCognitoIdentity.CognitoRefreshToken({
              RefreshToken: res.data.refresh_token,
            }),
          });
          console.log("cognitoSession:", cognitoSession);
          let tempUser = new AmazonCognitoIdentity.CognitoUser(userData);
          tempUser.setSignInUserSession(cognitoSession);
          console.log("tempUser:", tempUser);

          let token = cognitoSession.getIdToken().getJwtToken();
          console.log("token:", token);
          const user_session_results = await api.post(
            USER_SESSION_ENDPOINT,
            JSON.stringify({
              user_id: res.data.user_details.sub,
              user_email: res.data.user_details.email,
            }),
            token
          );
          console.log("create user session res:", user_session_results);
          if (user_session_results.status_code == 200) {
            localStorage.setItem("session_id", user_session_results.data.id);
          } else {
            console.log(
              "create user session err:",
              user_session_results.details
            );
            return;
          }

          localStorage.setItem("username", res.data.user_details.sub);
          localStorage.setItem("name", res.data.user_details.name);
          navigate("/user");
        })
        .catch((err) => {
          console.log("verify_auth_code err", err);
          setLoading(false);
        });
    }
  }, [currentURL]);

  useEffect(() => {
    // failed three times, call API
    if (loginFailCount === 3) {
      setLoading(true);
      api
        .post(
          DISABLE_ACCOUNT_ENDPOINT,
          JSON.stringify({ username: userName }),
          null
        )
        .then((res) => {
          console.log("disable_account result: ", res);
          setErrorText(
            "Your account has been locked for security reasons. Please click on Forget Password  below to reset your password. Please contact us at cereal@changiairport.com for assistance."
          );
          setLoading(false);
          setLoginFailCount(0);
        })
        .catch((err) => {
          console.log("disable_account err", err);
          setLoading(false);
        });
    }
  }, [loginFailCount]);

  const handleADFSClicked = () => {
    const ad_url =
      "https://" +
      cognito_domain +
      "/authorize?response_type=code&identity_provider=" +
      cognito_saml_provider +
      "&client_id=" +
      client_id +
      "&redirect_uri=" +
      "https://" +
      cognito_saml_redirect_url;
    console.log("ad_url:", ad_url);
    window.location.href = ad_url;
  };

  const handleCancelClicked = () => {
    console.log("Cancel Clicked");
    setErrorText(null);
    setShowOTP(false);
  };

  const handleConfirmClicked = () => {
    console.log("Confirm Clicked");
    console.log("OTP:", otp);
    console.log("Username:", userName);
    console.log("Password:", userPassword);
    console.log("Cognito:", cognitoUser.current);

    setErrorText("");
    setLoading(true);
    cognitoUser.current.sendCustomChallengeAnswer(otp, {
      onSuccess: async (data) => {
        console.log("customChallenge success: ", data);
        setLoading(false);
        localStorage.setItem(
          "username",
          data.idToken.payload["cognito:username"] || userName
        );
        localStorage.setItem("name", data.idToken.payload["name"] || userName);
        localStorage.setItem("email", userName);

        let token = data.idToken.jwtToken;

        const user_session_results = await api.post(
          USER_SESSION_ENDPOINT,
          JSON.stringify({
            user_id: data.idToken.payload["sub"],
            user_email: data.idToken.payload["email"],
          }),
          token
        );
        console.log("create user session res:", user_session_results);
        if (user_session_results.status_code == 200) {
          localStorage.setItem("session_id", user_session_results.data.id);
        } else {
          console.log("create user session err:", user_session_results.details);
          return;
        }

        const user_alt_results = await api.get(
          GET_USER_ALT_ENDPOINT,
          { uid: data.idToken.payload["sub"] },
          token
        );
        console.log("get user alt res:", user_alt_results);

        if (user_alt_results.status_code === 200) {
          let now = new Date();
          let expired_at = new Date(user_alt_results.data.password_expired_at);
          console.log(
            "Has password expired:",
            expired_at,
            now,
            expired_at <= now
          );
          if (expired_at <= now) {
            if (window.location.href.indexOf("change_password") < 0) {
              setOpen(true);
            }
          } else {
            navigate("/user");
          }
        } else {
          console.log("get user alt err:", user_alt_results.details);
          return;
        }
      },
      onFailure: async (error) => {
        console.log("customChallenge error: ", error);
        setLoading(false);
        setShowOTPResend(true);
        setErrorText("OTP incorrect. Please try login again.");
      },
    });
  };

  const handleChangePasswordClicked = async () => {
    console.log("change password clicked.");
    console.log("newPassword:", newPassword);
    console.log("newCfmPassword:", newCfmPassword);
    if (newPassword != newCfmPassword) {
      setErrorText("Password mismatched");
    } else {
      if (
        !validator.isStrongPassword(newPassword, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setErrorText(
          "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars"
        );
        return;
      }

      setLoading(true);
      const results = await api.post(
        CHECK_PASSWORD,
        JSON.stringify({
          username: userAttr.email,
          password: newPassword,
        })
      );
      console.log("Check Password Result:", results);
      if (results.data === false) {
        setErrorText(
          "Please choose a password that you have not used before. To help protect your account, choose a new password every time you reset it."
        );
        setLoading(false);
      } else {
        cognitoUser.current.completeNewPasswordChallenge(
          newPassword,
          {},
          {
            onSuccess: (result) => {
              console.log("New Password Logged in!", result);
              const idToken = result.getIdToken().getJwtToken();
              console.log("idToken:", idToken);

              api
                .post(
                  UPDATE_USER_DETAIL,
                  JSON.stringify({
                    user_id: result.idToken.payload["cognito:username"],
                    status: "CONFIRMED",
                    password: newPassword,
                  }),
                  idToken
                )
                .then((res) => {
                  console.log("update_user_info res", res);
                  setLoading(false);
                  localStorage.setItem(
                    "username",
                    result.idToken.payload["cognito:username"] || userName
                  );
                  localStorage.setItem(
                    "name",
                    result.idToken.payload["name"] || userName
                  );
                  navigate("/user");
                })
                .catch((err) => {
                  console.log("update_user_info err", err);
                  setLoading(false);
                });
            },
            onFailure: function (err) {
              console.log(err.message);
              setErrorText(err.message);
              setLoading(false);
            },
          }
        );
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorText(null);
    setLoading(true);

    const username = event.target[1].value.replaceAll(" ", "");
    const password = event.target[2].value;

    setUserName(username);
    setPassword(password);

    let checkRes = await api.post(
      IS_DISABLED_ACCOUNT_ENDPOINT,
      JSON.stringify({ username: userName }),
      null
    );
    console.log("checkRes:", checkRes);
    if (checkRes.status_code !== 200) {
      setErrorText(checkRes.details);
      setLoading(false);
      return;
    } else {
      if (checkRes.data === true) {
        // setErrorText(
        //   "Your account has been locked for security reasons. Please contact us at cereal@changiairport.com for assistance."
        // );
        setErrorText(checkRes.reason);
        setLoading(false);
        return;
      }
    }

    api
      .post(CHECK_ADMIN_ACCESS, JSON.stringify({ username }), null)
      .then((res) => {
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          if (
            !res.data.has_admin_access ||
            res.data.has_admin_access === false
          ) {
            setErrorText("Incorrect username or password.");
            setLoading(false);
            return;
          }
          // has admin access and continue authentication
          let authenticationData = {
            Username: username,
            Password: password,
          };
          let authenticationDetails =
            new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
          let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
          let userData = {
            Username: username,
            Pool: userPool,
          };
          let params = {
            UserPoolId: userPool /* required */,
            Username: username /* required */,
          };

          const _cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
          _cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");

          _cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
              console.log("Logged in!", result);
              setLoading(false);
              const idToken = result.getIdToken().getJwtToken();
              localStorage.setItem(
                "username",
                result.idToken.payload["cognito:username"] || username
              );
              localStorage.setItem(
                "name",
                result.idToken.payload["name"] || username
              );
              navigate("/user");
            },
            newPasswordRequired: (userAttr) => {
              console.log("userAttr:", userAttr);
              setIsFirstlogin(true);
              delete userAttr.email_verified;
              delete userAttr.phone_number_verified;
              console.log("new userAttr:", userAttr);
              setUserAttr(userAttr);
              setLoginBtnText("Login");
              alert("Please enter new password and login again.");
              setLoading(false);
            },
            customChallenge: async () => {
              setShowOTP(true);
              setLoading(false);
              setLastOTPResend(new Date());
            },
            onFailure: function (err) {
              console.log(err.message);
              let errMsg = err.message
                .replaceAll("PreAuthentication failed with error ", "")
                .replaceAll(
                  "User is disabled.",
                  "Your account has been locked for security reasons. Please contact us at cereal@changiairport.com for assistance."
                );
              if (errMsg === "Incorrect username or password.") {
                if (loginFailCount < 3) {
                  setLoginFailCount(loginFailCount + 1);
                }
              }
              setErrorText(errMsg);

              setLoading(false);
            },
          });
          cognitoUser.current = _cognitoUser;
        } else {
          setErrorText("Incorrect username or password.");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("check_admin_access err", err);
        setErrorText("Incorrect username or password.");
        setLoading(false);
      });
  };

  const handleResendClicked = () => {
    let timeDiff = new Date().getTime() - lastOTPResend.getTime();
    timeDiff = Math.floor(timeDiff / 60000);
    // if last otp resend ts is over or equals to 5 minutes
    if (timeDiff < 5) {
      alert("Please request OTP to resend 5 minutes after the last request.");
      return;
    }

    console.log("Resend Link Clicked.");
    let authenticationData = {
      Username: userName,
      Password: userPassword,
    };
    let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authenticationData
    );
    let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    let userData = {
      Username: userName,
      Pool: userPool,
    };
    const _cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    _cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");
    setShowOTPResend(true);
    setOTP("");
    setLoading(true);
    _cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        console.log("Logged in!", result);
        setLoading(false);
        const idToken = result.getIdToken().getJwtToken();
        localStorage.setItem(
          "username",
          result.idToken.payload["cognito:username"] || userName
        );
        localStorage.setItem(
          "name",
          result.idToken.payload["name"] || userName
        );
        navigate("/dashboard");
      },
      newPasswordRequired: (userAttr) => {
        console.log("userAttr:", userAttr);
        setIsFirstlogin(true);
        delete userAttr.email_verified;
        delete userAttr.phone_number_verified;
        console.log("new userAttr:", userAttr);
        setUserAttr(userAttr);
        setLoginBtnText("Login");
        alert("Please enter new password and login again.");
        setLoading(false);
      },
      customChallenge: async () => {
        setShowOTP(true);
        setLoading(false);
        setLastOTPResend(new Date());
        setErrorText("");
      },
      onFailure: function (err) {
        console.log(err.message);
        setErrorText(
          err.message.replaceAll("PreAuthentication failed with error ", "")
        );
        setLoading(false);
      },
    });
    cognitoUser.current = _cognitoUser;
  };

  return (
    <div
      className="row example-wrapper login-blk"
      style={{ display: "flex", justifyContent: "center" }}
    >
      {loading && <FullscreenLoader />}
      <div className="col-xs-12 col-sm-6 offset-sm-3 example-col login_media_screen">
        {!showOTP && !isFirstLogin && (
          <div style={{ margin: "20px 0px" }}>
            <img src={Logo} style={{ height: "62px" }} />
          </div>
        )}
        {showOTP && (
          <div className="blkConfirmOTP">
            <div
              className="mb-3"
              style={{ position: "relative", marginTop: "32px" }}
            >
              <p className="otp_label">
                An OTP has been emailed to you. Please check your email.
              </p>
              <p className="otp_label">
                Please note that the OTP is valid for 5 minutes only.
              </p>
              <p className="input_label">OTP</p>
              <OtpInput
                containerStyle="otp-parent"
                value={otp}
                onChange={setOTP}
                numInputs={6}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={"otp-input"}
                inputType={"number"}
              />
            </div>
            {errorText && <p className="error_text">{errorText}</p>}
            {showOTPResend && (
              <p className="otp_resend_link" onClick={handleResendClicked}>
                Resend OTP
              </p>
            )}
            <div className="Confirm_Button_group">
              <Button
                className="Button_cancel"
                id="btnBtnCancel"
                onClick={handleCancelClicked}
              >
                Cancel
              </Button>
              <Button
                className="Button_confirm"
                id="btnBtnConfirm"
                onClick={handleConfirmClicked}
              >
                Confirm
              </Button>
            </div>
          </div>
        )}
        {isFirstLogin && (
          <div className="blkChangePassword">
            <p
              style={{
                color: "#000",
                /* Body/Text */
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "18px",
                textAlign: "left",
                marginBottom: "32px",
              }}
            >
              Please enter your new password twice so we can verify you typed it
              in correctly.
            </p>
            <div
              className="mb-3"
              style={{ position: "relative", marginTop: "32px" }}
            >
              <div
                className="icon"
                onClick={() => {
                  setHideNewPassword(!hideNewPassword);
                }}
                style={{
                  backgroundImage: hideNewPassword
                    ? `url(${Unhide})`
                    : `url(${Hide})`,
                  cursor: "pointer",
                }}
              ></div>
              <div>
                <p className="input_label">New Password</p>
                <Input
                  name="new_password"
                  type={hideNewPassword ? "password" : "text"}
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    border: "1px solid #E4E1DE",
                  }}
                  // label="Password"
                  placeholder="Enter new password"
                  required={true}
                  minLength={8}
                  validationMessage={passwordValidationMessage}
                  autoComplete="off"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div
              className="mb-3"
              style={{ position: "relative", marginTop: "32px" }}
            >
              <div
                className="icon"
                onClick={() => {
                  setHideCfmNewPassword(!hideCfmNewPassword);
                }}
                style={{
                  backgroundImage: hideCfmNewPassword
                    ? `url(${Unhide})`
                    : `url(${Hide})`,
                  cursor: "pointer",
                }}
              ></div>
              <div>
                <p className="input_label">New Password Confirmation</p>
                <Input
                  name="new_cfm_password"
                  type={hideCfmNewPassword ? "password" : "text"}
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    border: "1px solid #E4E1DE",
                  }}
                  // label="Password"
                  placeholder="Enter new password"
                  required={true}
                  minLength={8}
                  validationMessage={passwordValidationMessage}
                  autoComplete="off"
                  onChange={(e) => setNewCfmPassword(e.target.value)}
                />
              </div>
            </div>
            {errorText && <p className="error_text">{errorText}</p>}
            <Button
              className="Button_change_password"
              id="btnBtnChangePassword"
              onClick={handleChangePasswordClicked}
            >
              Change My Password
            </Button>
          </div>
        )}
        {!showOTP && !isFirstLogin && (
          <form className="k-form" onSubmit={handleSubmit} ref={loginForm}>
            <fieldset style={{ border: "none", padding: "0px 16px" }}>
              <div className="mb-3">
                <p className="input_label">User ID</p>
                <Input
                  value={userName}
                  onChange={(e) =>
                    setUserName(e.target.value.replaceAll(" ", ""))
                  }
                  name="username"
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    border: "1px solid #E4E1DE",
                  }}
                  // label="First Name"
                  placeholder="Enter business email address"
                  // pattern={"[A-Za-z0-9]+"}
                  minLength={2}
                  required={true}
                  autoComplete="false"
                  // validationMessage={usernameValidationMessage}
                />
              </div>
              <div
                className="mb-3"
                style={{ position: "relative", marginTop: "32px" }}
              >
                <div
                  className="icon"
                  onClick={() => {
                    setHide(!hide);
                  }}
                  style={{
                    backgroundImage: hide ? `url(${Unhide})` : `url(${Hide})`,
                    cursor: "pointer",
                  }}
                ></div>
                <div>
                  <p className="input_label">Password</p>
                  <Input
                    value={userPassword}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    type={hide ? "password" : "text"}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      border: "1px solid #E4E1DE",
                    }}
                    // label="Password"
                    placeholder="Enter password"
                    required={true}
                    minLength={6}
                    maxLength={18}
                    validationMessage={passwordValidationMessage}
                    autoComplete="off"
                  />
                </div>
              </div>
            </fieldset>
            {errorText && <p className="error_text">{errorText}</p>}
            <div
              className="forgot_password"
              onClick={() => {
                navigate("/forgot_password");
              }}
            >
              Forgot password
            </div>
            <div className="Button_submit_blk">
              <input
                type="submit"
                className="Button_submit"
                id="btnLogin"
                value={loginBtnText}
              />
            </div>
          </form>
        )}

        {!showOTP && !isFirstLogin && (
          <div className="Button_group">
            <div className="breaker">
              <div className="breaker-dot-left"></div>
              <span>OR</span>
              <div className="breaker-dot-right"></div>
            </div>
            <Button
              className="Button_adfs"
              id="btnADLogin"
              onClick={handleADFSClicked}
            >
              CAG Login
            </Button>
          </div>
        )}

        {!showOTP && !isFirstLogin && (
          <div style={{ margin: "34px 0px" }}>
            <img src={FooterLogo} style={{ height: "50px" }} />
          </div>
        )}

        {open && (
          <Dialog title={""} onClose={() => setOpen(false)}>
            <div
              style={{
                textAlign: "center",
                marginLeft: "30px",
                marginRight: 30,
              }}
            >
              <h4>{"Password has expired. Please change your password."}</h4>
              <p>
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  style={{ width: "300px" }}
                  onClick={() => {
                    setOpen(false);
                    navigate("/change_password");
                  }}
                >
                  OK
                </button>
              </p>
            </div>
          </Dialog>
        )}
      </div>
    </div>
  );
};
