import { Input } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import Delete from "../../assets/images/Icon/delete.svg";
import PlusIcon from "../../assets/images/Icon/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import "./package.css";
import api from "../../config/api";
import config from "../../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import { fetchGeneralConfig } from "../../store/generalConfigSlice";
import { Loader } from "@progress/kendo-react-all";

const PackageManagment = () => {
  const { general_config_data } = useSelector((state) => state.generalConfig);
  const { EDIT_PACKAGE } = config.api_endpoint;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [all_packages, setAll_packages] = useState([
    ...general_config_data.general_config_data.package,
  ]);
  const [editMode, setEditMode] = useState(false);

  console.log(editMode && all_packages.find((i) => i === ""));

  const filterPackage = (value, id) => {
    all_packages.splice(id, 1);
    setAll_packages([...all_packages]);
  };

  const editPackage = (value, id) => {
    all_packages[id] = value;
    setAll_packages([...all_packages]);
  };

  const callAPI = async () => {
    setLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .post(
        EDIT_PACKAGE,
        JSON.stringify({
          data_type: "packages",
          action: "update",
          data: all_packages,
        }),
        token
      )
      .then((res) => {
        if (res.status_code === 200) {
          setAll_packages([...res.data]);
          dispatch(fetchGeneralConfig());
          setLoading(false);
        } else {
          alert(res.error);
        }
      });

    setEditMode(false);
  };

  const handleEdit = () => {
    if (editMode) {
      callAPI();
    }
    setEditMode(!editMode);
  };

  return (
    <>
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            minHeight: "100%",
          }}
        />
      ) : (
        <div className="package_main_box">
          <div className="package_title">Packages</div>
          <div className="package_child_box">
            {all_packages.map((i, id) => (
              <div key={id} className="package_input_box">
                <Input
                  style={{ borderRadius: "8px" }}
                  value={i}
                  disabled={editMode ? false : true}
                  onChange={(e) => editPackage(e.target.value, id)}
                />
                {editMode && (
                  <div
                    className="package_delete_icon"
                    onClick={() => filterPackage(i, id)}
                  >
                    <img src={Delete} />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div style={{ position: "relative" }}>
            <div className="package_footer_box">
              {editMode && (
                <div
                  className="package_footer_button package_footer_add_button"
                  onClick={() => setAll_packages([...all_packages, ""])}
                >
                  Add
                </div>
              )}
              <div
                className="package_footer_button package_footer_edit_button"
                onClick={handleEdit}
                style={{
                  pointerEvents:
                    editMode && all_packages.find((i) => i === "") !== undefined
                      ? "none"
                      : "",
                  opacity:
                    editMode && all_packages.find((i) => i === "") !== undefined
                      ? "0.5"
                      : "",
                }}
              >
                {editMode ? "Save" : "Edit"}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PackageManagment;
