import React, { useEffect } from "react";
const { signout } = require("./config/userPool");

const BrowserTabCloseEventLogout = () => {
  const logout = () => {
    try {
      console.log("Logout");
      signout();
      localStorage.removeItem("login");
      localStorage.removeItem("username");
      localStorage.removeItem("shouldLogout");
      localStorage.removeItem("session_id");
      window.location.href = "/";
      localStorage.setItem("redirect_to", window.location.href);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // window.addEventListener("beforeunload", function(e) {

    //     try{
    //         logout();
    //     }
    //     catch(e){
    //         console.log(e);
    //     }
    // });

    // return () => {
    //     window.addEventListener("beforeunload", function(e) {

    //         try{
    //             logout();
    //         }
    //         catch(e){
    //             console.log(e);
    //         }

    //     });
    // }
    if (window.performance) {
      if (performance.navigation.type == 1) {
        // alert("This page is reloaded");
        // logout();
      } else {
        // alert("This page is not reloaded");
        logout();
      }
    }
  }, []);

  return <div></div>;
};
export default BrowserTabCloseEventLogout;
